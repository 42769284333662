import React from "react";
import "../assets/App.css";
import im from "../assets/_img_e_1.svg";
import im_s from "../assets/_img_e_1_s.jpg";
import { motion, AnimatePresence } from "framer-motion";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const variants = {
    hidden_up: {
        opacity: 0,
        zIndex: 0,
        y: 50
    },
    hidden_down: {
        opacity: 0,
        zIndex: 0,
        y: -50
    },
    center: {
        y: 0,
        zIndex: 1,
        opacity: 1
    }
}

const Contact = () => {
    return (
        <AnimatePresence initial="false">
            <div className="content_main">

                <motion.div
                    initial="hidden_down"
                    animate="center"
                    exit="hidden_up"
                    variants={variants}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    <LazyLoadImage className="inner_img"
                        placeholderSrc={im_s}
                        style={{
                            maxWidth: "85%",
                            userSelect: "none",
                            WebkitUserSelect: "none",
                            MozUserSelect: "none"
                        }}
                        src={im}
                        alt="background line drawing"
                        effect="opacity" />
                </motion.div>

                <motion.div className="inner"
                    id="contact"
                    initial="hidden_up"
                    animate="center"
                    exit="hidden_down"
                    variants={variants}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    <div className="_i">
                        <h1>Contact</h1>
                        For media/ rights inquiries, please contact:
                        <br /><br />

                        <p id="fm">
                            Suzie Townsend<br />
                            New Leaf Literary & Media<br />
                            110 West 40th Street, <br />
                            Suite 2201<br />
                            New York, NY 10018<br />
                            Email: assist@newleafliterary.com
                        </p>
                    </div>
                </motion.div>

            </div >
        </AnimatePresence >
    );
}
export default Contact;