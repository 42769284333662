import React from "react";
import { NavLink as Link } from "react-router-dom";
import "../assets/App.css";
import Lo from "../assets/lg.svg";

const Logo = () => {
    return (
        <Link to="/">
            <img className="logo" src={Lo} alt="logo" />
        </Link>
    );
}

export default Logo;