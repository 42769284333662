import React, { useState, useRef, Suspense } from "react";
import "../assets/App.css";
import { motion, AnimatePresence } from "framer-motion";
import { Canvas, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { Scroll, ScrollControls, Preload, useTexture, Bounds, ContactShadows, MeshWobbleMaterial, CubicBezierLine, Plane } from '@react-three/drei';


const hiddenMask = `repeating-radial-gradient(circle, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 10px, rgba(0,0,0,1) 10px, rgba(0,0,0,1) 10px)`;
const visibleMask = `repeating-radial-gradient(circle, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,3) 0px, rgba(0,0,0,1) 15px)`;

const variants = {
    hide: {
        opacity: 0,
        x: "100%",
        transition: {
            type: "spring",
            stiffness: 400,
            damping: 15,
            duration: 0.8
        }
    },
    show: {
        opacity: 1,
        x: 0,
        transition: {
            type: "spring",
            stiffness: 400,
            damping: 15,
            duration: 0.8
        }
    },
    enter: {
        opacity: 0,
    },

    exit: {
        opacity: 0,
        zIndex: 0,
        WebkitMaskImage: [visibleMask, hiddenMask],
        maskImage: [hiddenMask, visibleMask],
        transition: { duration: 0.5, delay: 0.1 },
    },
    center: {
        zIndex: 1,
        y: 0,
        opacity: 1,
        WebkitMaskImage: [hiddenMask, visibleMask],
        maskImage: [visibleMask, hiddenMask],
        transition: { duration: 1, delay: 0.5 },
        //        viewport: { once: true }
    }
}



const Cover = ({ map, im, col, pos, ro, sc, ...props }) => {
    const ref = useRef();
    const mesh = useRef();
    const [selected, setSelected] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Plane
            args={[7.8, 9.5]}
            ref={ref}
            position={pos}
            scale={1.1}
            onClick={() => setIsOpen(!isOpen)}
            onPointerEnter={() => setSelected(!selected)}
            onPointerLeave={() => setSelected(!selected)}
            {...props}
        >
            <MeshWobbleMaterial transparent map={selected ? im : map} speed={1} factor={0.12} ref={mesh} />
        </Plane >
    );
}

const Covers = ({ ...props }) => {
    const meshRef = useRef();
    const cover = useRef();
    const colors = ["#F1DEBA", "#FBCADB", "#CACFC1", "#E2D6DD", "#FBECDB"];
    const [c1, c2, c3, c4, c5] = useTexture([
        "/_as/b_tp.png",
        "/_as/b_htl.png",
        "/_as/b_son.png",
        "/_as/b_noh.png",
        "/_as/b_ewb.png"
    ]);

    const [b1, b2, b3, b4, b5] = useTexture([
        "/_as/b1.png",
        "/_as/b2.png",
        "/_as/b3.png",
        "/_as/b4.png",
        "/_as/b5.png"
    ]);
    return (
        <group {...props} ref={cover} scale={1.9} position={[-5, 0.5, -5]}>
            <Cover ref={meshRef} pos={[-10, 0, 0.5]} map={c1} im={b1} col={colors[0]} {...props} />
            <Cover ref={meshRef} pos={[-1, 0, 0.5]} map={c2} im={b2} col={colors[1]} {...props} />
            <Cover ref={meshRef} pos={[9, 0, 0.5]} map={c3} im={b3} col={colors[2]}{...props} />
            <Cover ref={meshRef} pos={[19, 0, 0.5]} map={c4} im={b4} col={colors[3]} {...props} />
            <Cover ref={meshRef} pos={[29, 0, 0.5]} map={c5} im={b5} col={colors[4]} {...props} />
        </group>
    );
}

function Bg({ ...props }) {
    const mesh = useRef();
    const pos = Array.from({ length: 1000 }, (n) => [THREE.MathUtils.randFloatSpread(50), THREE.MathUtils.randFloatSpread(50), THREE.MathUtils.randFloatSpread(30)])
    useFrame(() => {
    })
    return (
        <mesh ref={mesh} scale={100} >
            <ambientLight intensity={0.1} />
            <CubicBezierLine segments dashed
                start={[[0, 0, 0], [pos]]}
                midA={pos}
                midB={pos}
                end={pos}
                color={"aquamarine"}
                lineWidth={2}
                {...props}
            />
            <meshBasicMaterial color={"#f8f7e6"} />


        </mesh>
    );
}

const Books = ({ ...props }) => {

    return (
        <AnimatePresence mode="wait" initial={false}>
            <motion.div className="content_msc"
                initial="enter"
                animate="center"
                variants={variants}
                style={{
                    overflow: "hidden"
                }}
                exit="exit">
                <div className="book_list">
                    <Canvas camera={{ position: [0, 0, 5], fov: [100] }} dpr={[1, 2]}>
                        <Bg />
                        <Suspense fallback={null}>
                            <ScrollControls horizontal infinite pages={2} style={{ scrollBehavior: 'smooth', scrollbarColor: "#F5F0EB", scrollbarWidth: "thin" }}>
                                <Scroll>
                                    <Bounds margin={0.1} observe clip>
                                        <ambientLight intensity={0.4} />
                                        <Covers />
                                        <ContactShadows />
                                    </Bounds>
                                    <Preload all />
                                </Scroll>
                            </ScrollControls>
                        </Suspense>
                    </Canvas>
                </div>
            </motion.div >
        </AnimatePresence >
    );
}
export default Books;