import React, { useEffect, useRef, useState } from "react";
import s01 from "../assets/s_06.png";
import s02 from "../assets/s_01.png";
import s03 from "../assets/s_02.png";
import s01_s from "../assets/s_06-min.png";
import s02_s from "../assets/s_01-min.png";
import s03_s from "../assets/s_02-min.png";
import "../assets/App.css";
import { AnimatePresence, motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { wrap } from "popmotion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';

const imgs = [s01, s02, s03];
const imgs_s = [s01_s, s02_s, s03_s];


const hiddenMask = `repeating-radial-gradient(circle, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 10px, rgba(0,0,0,1) 10px, rgba(0,0,0,1) 10px)`;
const visibleMask = `repeating-radial-gradient(circle, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,3) 0px, rgba(0,0,0,1) 15px)`;

const variants = {
    enter: {
        opacity: 0,
        zIndex: 0,
        transition: { duration: 1, delay: 0.3 },
    },
    exit: {
        opacity: 0,
        zIndex: 0,
        transition: { duration: 0.5, delay: 0.1 },
    },
    center: {
        zIndex: 1,
        opacity: 1,
        WebkitMaskImage: [hiddenMask, visibleMask],
        maskImage: [hiddenMask, visibleMask],
        transition: { duration: 1.5, delay: 0.2 }
    }
}


const images = [
    "slide_1",
    "slide_2",
    "slide_3"
];

const words = [
    `Four years ago, Skye Nickson’s father and older brother mysteriously disappeared. The official story is that they are on the run, but what that means for Skye is that she has had to learn how to live without Finn, her brother and best friend. Finn was capital-s Spectacular. So much so that their mother often expects her to act just like Finn, to be a Rent-a-Finn.
    But everything changes when the news breaks that Finn has been found. Finn is fourteen now, quiet and tall and full of so many secrets. In fact, Finn is so different that Skye has no choice but to wonder if there’s been a horrible mistake. Maybe the boy who came home isn’t her brother after all.`,

    `Everything eleven-year-old Kemi Carter thought she knew changes when she sees an asteroid hover in the sky. Amplus-68 has an 84.7% chance of colliding with earth in four days, and with that collision, Kemi’s life as she knows it will end.`,

    `When her sister Rose disappeared, seventeen-year-old Emmy lost a part of herself. Everyone else seems convinced she ran away and will reappear when she’s ready, but Emmy isn’t so sure. So Emmy returns to their Ohio hometown for a summer, determined to uncover clues that can lead her back to Rose once and for all.`,


];

const ti = [
    "The Shape of Lost Things",
    "The Probability of Everything",
    "How to Live Without You"

]




const more = [
    "./tsolt",
    "./tpoe",
    "./htl"
]

const buy = [
    "https://www.harpercollins.com/products/the-shape-of-lost-things-sarah-everett?variant=41471216418850",
    "https://www.harpercollins.com/products/the-probability-of-everything-sarah-everett",
    "https://www.harpercollins.com/products/how-to-live-without-you-sarah-everett?variant=39935476727842"
]


const Slideshow = ({ isVisible }) => {
    const navigate = useNavigate();
    const timeref = useRef(null);
    const [[current, active], setCurrent] = useState([0, 0]);
    const divIndex = wrap(0, words.length, current);
    const imgIndex = wrap(0, images.length, current);
    const [isLoaded, setIsLoaded] = useState(false);


    function timeoutReset() {
        clearTimeout(timeref.current);
    }

    useEffect(() => {
        timeoutReset();
        timeref.current = setTimeout((newActive = 1) => {
            if (current === words.length) {
                setCurrent([current - words.length + newActive, current]);
            }
            else
                setCurrent([current + newActive, current]);
        }, 8000);
        return () => timeoutReset(timeref.current);
    }, [current]);





    return (
        <AnimatePresence initial="false">
            <div className="outer_slides">

                <div
                    id="inner_slides"
                    //custom={active}
                    onLoad={() => setIsLoaded(!isLoaded)}>

                    <motion.div
                        className="main_qt"
                        custom={active}
                        key={active}
                        initial={{ opacity: 0, zIndex: 0, y: 30 }}
                        animate={{ opacity: 1, zIndex: 1, y: 0 }}
                        transition={{ duration: 1.3, delay: 0.2 }}
                        exit={{ opacity: 0 }}

                    >

                        {current === 1 ? (

                            <div
                                id="b_rev_"


                            >

                                <div className="_bx">
                                    <div className="_b">An instant classic.” </div><br />
                                    <i>— Christine Day, award winning author of I Can Make This Promise</i> </div>
                                <div className="_bx">
                                    <div className="_b">"...heartrending, hopeful, and palpably felt."</div>  <br />
                                    <i>— Publishers Weekly (starred review)</i> &#9733;</div>
                                <div className="_bx">
                                    <div className="_b">"...gut-wrenching novel...  </div><br />
                                    <i>— Kirkus Reviews (starred review)</i> &#9733;</div>
                                <div className="_bx">
                                    <div className="_b">"Thought-provoking and utterly unique..." </div><br />
                                    <i>— School Library Journal (starred review)</i> &#9733;</div>

                            </div>
                        ) : ("")}

                        <h4>
                            {ti[divIndex]}
                        </h4>

                        <div className="words">
                            {words[divIndex]}
                            <div id="links_nav">
                                <Link to={more[divIndex]} className="buttons" onClick={() => navigate(`more[divIndex]`)}>Read More</Link>
                                <Link to={buy[divIndex]} className="buttons" target="_blank">Buy</Link>
                            </div>
                        </div>
                    </motion.div>


                    <Link to={more[divIndex]}>
                        <motion.div
                            initial="exit"
                            animate="center"
                            transition={{
                                type: "spring", damping: 30, stiffness: 150, restDelta: 0.001, opacity: { duration: 1 }
                            }}
                            exit="exit"
                            key={current}
                            custom={active}
                            variants={variants}>

                            <LazyLoadImage className="slide_img"
                                src={imgs[imgIndex]}
                                placeholderSrc={imgs_s[imgIndex]}
                                alt={`${ti[imgIndex]} cover`}
                                effect="opacity"
                                onLoad={() => setIsLoaded(!isLoaded)}
                                onClick={() => navigate(`more[divIndex]`)}
                            />
                        </motion.div>
                    </Link>

                    <div className="slide_dots">
                        <div className="slide_dot" onClick={() => setCurrent([0, current])} />
                        <div className="slide_dot" onClick={() => setCurrent([1, current])} />
                        <div className="slide_dot" onClick={() => setCurrent([2, current])} />
                    </div>
                </div>
            </div >

        </AnimatePresence>
    );
}


const Home = () => (
    <Slideshow />
);
export default Home;
