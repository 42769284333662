import React from "react";
import bk from "../assets/b3.png";
import bk_s from "../assets/b3-min.png";
import "../assets/App.css";
import { motion, AnimatePresence } from "framer-motion";
import { NavLink as Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';


const variants = {
    hidden_up: {
        opacity: 0,
        zIndex: 0,
        y: 50
    },
    hidden_down: {
        opacity: 0,
        zIndex: 0,
        y: -50
    },
    center: {
        y: 0,
        zIndex: 1,
        opacity: 1
    }
}

const SON = () => {
    return (
        <AnimatePresence initial="false">
            <div className="content_main">

                <motion.div className={"inner_cover"}
                    initial="hidden_down"
                    animate="center"
                    exit="hidden_up"
                    variants={variants}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    <LazyLoadImage className="inner_img"
                        placeholderSrc={bk_s}
                        style={{
                            maxWidth: "75%",
                            userSelect: "none",
                            WebkitUserSelect: "none",
                            MozUserSelect: "none",
                            pointerEvents: "none"
                        }}
                        src={bk}
                        alt="Some Other Now cover"
                        effect="opacity" />
                </motion.div>

                <motion.div className="inner_bks"
                    initial="hidden_up"
                    animate="center"
                    exit="hidden_down"
                    variants={variants}
                    transition={{ duration: 1, delay: 0.5 }}
                >

                    <motion.div className="b_box"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.5, duration: 2 }}
                        exit="exit">

                        <div className="b_info">
                            <Link to="https://www.harpercollins.com/products/some-other-now-sarah-everett?variant=39935639912482" target="_blank"> <button className="b_links">HarperCollins Books</button></Link>

                            <Link to="https://www.audible.com/pd/Some-Other-Now-Audiobook/0358437792?qid=1686102329" target="_blank"> <button className="b_links">Audible</button></Link>

                            <Link to="https://www.indiebound.org/book/9780358251866" target="_blank"> <button className="b_links">Indiebound</button></Link>

                            <Link to="https://www.amazon.com/Some-Other-Now-Sarah-Everett/dp/0358251869" target="_blank" ><button className="b_links">Amazon</button></Link>

                            <Link to="https://www.chapters.indigo.ca/en-ca/books/some-other-now/9780358251866-item.html" target="_blank" ><button className="b_links">Indigo</button></Link>

                            <Link to="https://www.goodreads.com/book/show/44651758-some-other-now" target="_blank"> <button className="b_links">Goodreads</button></Link>
                        </div>
                    </motion.div >

                    <h2>Some Other Now</h2>
                    <div className="bk_inner">
                        <p >
                            Before she kissed one of the Cohen boys, seventeen-year-old Jessi Rumfield knew what it was like to have a family—even if technically, that family didn’t belong to her. Rowan Cohen was Jessi’s best friend. Mel was the mother she always wanted. And Luke was…Luke. He was Jessi’s first crush, the boy she spent all summer day-dreaming about.
                        </p>
                        <p>
                            But then everything changed. Disaster struck and Jessi lost her place in the family she loved so much.
                        </p>
                        <p>
                            A year later, when Luke comes home from college and asks Jessi to pretend to be his girlfriend for the final months of Mel’s life, Jessi finds herself drawn back into the world of the Cohens. Everything’s changed, but Jessi can’t help wanting to go back, even if it means breaking her heart and losing them forever this time.
                        </p>

                    </div>
                </motion.div >
            </div >
        </AnimatePresence >
    );
}
export default SON;