import React from "react";
import bk from "../assets/b6.png";
import bk_s from "../assets/s_06-min.png";
import "../assets/App.css";
import { motion, AnimatePresence } from "framer-motion";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { NavLink as Link } from "react-router-dom";



const variants = {
    hidden_up: {
        opacity: 0,
        zIndex: 0,
        y: 50
    },
    hidden_down: {
        opacity: 0,
        zIndex: 0,
        y: -50
    },
    center: {
        y: 0,
        zIndex: 1,
        opacity: 1
    }
}

const TSOLT = () => {
    return (
        <AnimatePresence initial="false">
            <div className="content_main">

                <motion.div className={"inner_cover"}
                    initial="hidden_down"
                    animate="center"
                    exit="hidden_up"
                    variants={variants}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    <LazyLoadImage className="inner_img"
                        placeholderSrc={bk_s}
                        style={{
                            maxWidth: "75%",
                            userSelect: "none",
                            WebkitUserSelect: "none",
                            MozUserSelect: "none",
                            pointerEvents: "none"
                        }}
                        src={bk}
                        alt="The Shape of Lost Things cover"
                        effect="opacity" />
                </motion.div>

                <motion.div className="inner_bks"
                    initial="hidden_up"
                    animate="center"
                    exit="hidden_down"
                    variants={variants}
                    transition={{ duration: 1, delay: 0.5 }}
                >


                    <motion.div className="b_box"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.5, duration: 2 }}
                        exit={{ opacity: 0 }}>

                        <div className="b_info">
                            <Link to="https://www.harpercollins.com/products/the-shape-of-lost-things-sarah-everett" target="_blank"> <button className="b_links">HarperCollins Books</button></Link>

                            <Link to="https://www.audible.com/pd/The-Shape-of-Lost-Things-Audiobook/B0CT4LS8FH" target="_blank"> <button className="b_links">Audible</button></Link>

                            <Link to="https://bookshop.org/search?keywords=sarah+everett" target="_blank"> <button className="b_links">Indiebound</button></Link>

                            <Link to="https://www.amazon.com/Shape-Lost-Things-Sarah-Everett/dp/0063256614" target="_blank" ><button className="b_links">Amazon</button></Link>

                            <Link to="https://www.indigo.ca/en-ca/the-shape-of-lost-things/9780063256613.html" target="_blank" ><button className="b_links">Indigo</button></Link>

                            <Link to="https://www.goodreads.com/book/show/199531637-the-shape-of-lost-things" target="_blank"> <button className="b_links">Goodreads</button></Link>
                        </div>
                    </motion.div >

                    <h2>The Shape of Lost Things</h2>
                    <div className="bk_inner">
                        <p>
                            Skye Nickson’s world changed forever when her dad went on the run with her brother Finn. It’s been three years without Finn’s jokes, three years without her father’s old soul music, and three years of Skye filling in as Rent-a-Finn for their mom. Finn’s birthday is always difficult, but at least Skye has her best friends Reece and Jax to lean on, even if Reece has started acting too cool for them.</p>
                        <p>
                            But this year is different, because after Finn’s birthday, they get a call that he’s finally been found. Tall, quiet, and secretive, this Finn is nothing like the brother she grew up with. He keeps taking late night phone calls, losing his new expensive gifts, and he doesn’t seem to remember any of their inside jokes or secrets.</p>
                        <p>
                            As Skye tries to make sense of it all through the lens of her old Polaroid camera, she starts to wonder: could this Finn be someone else entirely? And if everyone else has changed, does it mean that Skye has to change too?
                        </p>


                    </div>
                </motion.div >
            </div >
        </AnimatePresence >
    );
}
export default TSOLT;