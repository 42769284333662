import React, { StrictMode } from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

//Component File
import MainContainer from "./components/MainContainer";


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <StrictMode>
        <BrowserRouter>
            <MainContainer />
        </BrowserRouter>
    </StrictMode >
);


