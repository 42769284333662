import React, { useState } from "react";
import NavBar from "./NavBar";
import "../assets/App.css";
import Logo from "./Logo";
import { NavLink as Link } from "react-router-dom";


const Min = () => {
    const [showMain, setShowMain] = useState(false);
    const [showSubA, setShowSubA] = useState(false);
    const [showSubB, setShowSubB] = useState(false);

    function handleClickMain(e) {
        setShowMain(!showMain);

        if (showMain === "false") {
            setShowSubA(false);
            setShowSubB(false);
        }
    }

    function handleClickSub(e) {

        if (e.target.className === "min_a") {
            setShowSubB(false);
            setShowSubA(!showSubA);
        }
        else {
            setShowSubA(false);
            setShowSubB(!showSubB);
        }
    }

    return (

        <div className="min_nav">
            <button id="min_btn"
                onClick={handleClickMain}>
            </button>
            {showMain ? (
                <>
                    <div id="outer_min" onClick={() => { setShowMain(false) }} />
                    <nav id="min_navdown">
                        <ul>
                            <li><Link to="/"> Home</Link></li>
                            <li className="min_a" onClick={handleClickSub}>
                                Author
                                {showSubA ? (
                                    <ul className="sub" id="minauthor">
                                        <li className="btn"><Link to="/about"> About</Link></li>
                                        <li className="btn"><Link to="/media"> Media </Link></li>
                                    </ul>
                                ) : null}
                            </li>

                            <li className="min_b" onClick={handleClickSub}>
                                Books
                                {showSubB ? (
                                    <ul className="sub" id="minbooks">
                                        <li className="btn"><Link to="/ewb"> Everyone We've Been</Link></li>
                                        <li className="btn"><Link to="/htl"> How to Live Without You</Link></li>
                                        <li className="btn"><Link to="/noh"> No One Here is Lonely</Link></li>
                                        <li className="btn"><Link to="/tpoe"> The Probability of Everything </Link></li>
                                        <li className="btn"><Link to="/tsolt"> The Shape of Lost Things </Link></li>
                                        <li className="btn"><Link to="/son"> Some Other Now</Link></li>
                                    </ul>
                                ) : null}
                            </li>
                            <li><Link to="/contact"> Contact </Link></li>
                        </ul>
                    </nav>
                </>
            ) : null}
        </div>
    );
}



const Header = () => {

    return (
        <>
            <Logo />
            <NavBar />
            <Min />
        </>
    );
}

export default Header;

