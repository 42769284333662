import { NavLink as Link } from "react-router-dom";
import "../assets/App.css";

const NavBar = () => {
    return (
        <>
            <nav className="menu">
                <ul>
                    <li><Link to="/"> Home</Link></li>
                    <li className="menudown">
                        Author
                        <ul className="sub">
                            <li className="btn"><Link to="/about"> About</Link></li>
                            <li className="btn"><Link to="/media"> Media </Link></li>
                        </ul>
                    </li>

                    <li className="menudown">
                        Books
                        <ul className="sub">
                            <li className="btn"><Link to="/ewb"> Everyone We've Been</Link></li>
                            <li className="btn"><Link to="/htl"> How to Live Without You</Link></li>
                            <li className="btn"><Link to="/noh"> No One Here is Lonely</Link></li>
                            <li className="btn"><Link to="/tpoe"> The Probability of Everything </Link></li>
                            <li className="btn"><Link to="/tsolt"> The Shape of Lost Things </Link></li>
                            <li className="btn"><Link to="/son"> Some Other Now</Link></li>
                        </ul>
                    </li>
                    <li><Link to="/contact"> Contact </Link></li>
                </ul>
            </nav>
        </>
    );
}

export default NavBar;