import React from "react";
import bk from "../assets/b5.png";
import bk_s from "../assets/b5-min.png";
import "../assets/App.css";
import { motion, AnimatePresence } from "framer-motion";
import { NavLink as Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';


const variants = {
    hidden_up: {
        opacity: 0,
        zIndex: 0,
        y: 50
    },
    hidden_down: {
        opacity: 0,
        zIndex: 0,
        y: -50
    },
    center: {
        y: 0,
        zIndex: 1,
        opacity: 1
    }
}

const EWB = () => {
    return (
        <AnimatePresence initial="false">
            <div className="content_main">
                <motion.div className={"inner_cover"}
                    initial="hidden_down"
                    animate="center"
                    exit="hidden_up"
                    variants={variants}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    <LazyLoadImage className="inner_img"
                        placeholderSrc={bk_s}
                        style={{
                            //maxWidth: "75%",
                            userSelect: "none",
                            WebkitUserSelect: "none",
                            MozUserSelect: "none",
                            pointerEvents: "none"
                        }}
                        src={bk}
                        alt="Everyone We've Been cover"
                        effect="opacity" />
                </motion.div>

                <motion.div className="inner_bks"
                    initial="hidden_up"
                    animate="center"
                    exit="hidden_down"
                    variants={variants}
                    transition={{ duration: 1, delay: 0.5 }}
                >

                    <motion.div className="b_box"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.5, duration: 2 }}
                        exit={{ opacity: 0 }}>

                        <div className="b_info">
                            <Link to="http://www.penguinrandomhouse.com/books/252773/everyone-weve-been-by-sarah-everett/" target="_blank"> <button className="b_links">Penguin Random House</button></Link>

                            <Link to="http://www.indiebound.org/book/9780553538441" target="_blank"> <button className="b_links">Indiebound</button></Link>

                            <Link to="http://www.amazon.com/Everyone-Weve-Been-Sarah-Everett/dp/0553538446/" target="_blank" ><button className="b_links">Amazon</button></Link>

                            <Link to="https://www.chapters.indigo.ca/en-ca/books/everyone-weve-been/9780553538465-item.html" target="_blank" ><button className="b_links">Indigo</button></Link>

                            <Link to="https://www.goodreads.com/book/show/28503698-everyone-we-ve-been" target="_blank"> <button className="b_links">Goodreads</button></Link>
                        </div>
                    </motion.div >
                    <h2>Everyone We've Been</h2>
                    <div className="bk_inner">
                        <p >
                            Addison Sullivan has been in an accident. In its aftermath, she has memory lapses and starts talking to a boy that no one else can see. It gets so bad that she’s worried she’s going crazy.
                        </p>
                        <p>
                            Addie takes drastic measures to fill in the blanks and visits a shadowy medical facility that promises to “help with your memory.” But at the clinic, Addie unwittingly discovers it is not her first visit. And when she presses, she finds out that she had certain memories erased. She had a boy erased.
                        </p>
                        <p>
                            But why? Who was that boy, and what happened that was too devastating to live with? And even if she gets the answers she’s looking for, will she ever be able to feel like a whole person again?
                        </p>

                    </div>
                </motion.div >
            </div >
        </AnimatePresence>
    );
}
export default EWB;