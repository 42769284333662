import React from "react";
import { Outlet } from "react-router-dom";


const Content = () => {
    return (
        <div className="outlet">
            <Outlet />
        </div>
    )
}
export default Content;