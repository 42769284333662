import React from "react";
import "../assets/App.css";
import im from "../assets/_img_er_1.svg";
import im_s from "../assets/_img_er_1_s.jpg";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const ErrorPage = () => {

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    return (
        <AnimatePresence initial="false">
            <motion.div className="content_main"
                key={"err"}
                initial={{ opacity: 0, zIndex: 0 }}
                animate={{
                    zIndex: 1,
                    y: [-40, 0],
                    opacity: 1,
                    //transition: { duration: 1.5, delay: 0.2 }
                }}
                transition={{ duration: 1.5, delay: 0.5 }}
                exit={{ opacity: 0, zIndex: 0 }}
            >
                <LazyLoadImage className="inner_img"
                    placeholderSrc={im_s}
                    style={{
                        maxWidth: "85%",
                        userSelect: "none",
                        WebkitUserSelect: "none",
                        MozUserSelect: "none",
                        pointerEvents: "none"
                    }}
                    src={im}
                    alt="background line drawing"
                    effect="opacity" />

                <div className="inner" id="error">
                    <div className="inner_error">
                        Sorry, nothing in here...
                        <br /><br />
                        <button className="error_button" onClick={goBack}>Take Me Back</button>
                    </div >
                </div>
            </motion.div >
        </AnimatePresence>
    );
}

export default ErrorPage;