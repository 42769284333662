import React from "react";
import bk from "../assets/b1.png";
import bk_s from "../assets/b1-min.png";
import "../assets/App.css";
import { motion, AnimatePresence } from "framer-motion";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { NavLink as Link } from "react-router-dom";



const variants = {
    hidden_up: {
        opacity: 0,
        zIndex: 0,
        y: 50
    },
    hidden_down: {
        opacity: 0,
        zIndex: 0,
        y: -50
    },
    center: {
        y: 0,
        zIndex: 1,
        opacity: 1
    }
}

const TPOE = () => {
    return (
        <AnimatePresence initial="false">
            <div className="content_main">

                <motion.div className={"inner_cover"}
                    initial="hidden_down"
                    animate="center"
                    exit="hidden_up"
                    variants={variants}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    <LazyLoadImage className="inner_img"
                        placeholderSrc={bk_s}
                        style={{
                            maxWidth: "75%",
                            userSelect: "none",
                            WebkitUserSelect: "none",
                            MozUserSelect: "none",
                            pointerEvents: "none"
                        }}
                        src={bk}
                        alt="The Probability of Everything cover"
                        effect="blur" />
                </motion.div>

                <motion.div className={"inner_cover"}
                    initial="hidden_down"
                    animate="center"
                    exit="hidden_up"
                    variants={variants}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    <LazyLoadImage className="inner_img"
                        placeholderSrc={bk_s}
                        style={{
                            maxWidth: "75%",
                            userSelect: "none",
                            WebkitUserSelect: "none",
                            MozUserSelect: "none",
                            pointerEvents: "none"
                        }}
                        src={bk}
                        alt="The Probability of Everything cover"
                        effect="opacity" />
                </motion.div>

                <motion.div className="inner_bks"
                    initial="hidden_up"
                    animate="center"
                    exit="hidden_down"
                    variants={variants}
                    transition={{ duration: 1, delay: 0.5 }}
                >


                    <motion.div className="b_box"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.5, duration: 2 }}
                        exit={{ opacity: 0 }}>

                        <div className="b_info">
                            <Link to="https://www.harpercollins.com/products/the-probability-of-everything-sarah-everett" target="_blank"> <button className="b_links">HarperCollins Books</button></Link>

                            <Link to="https://www.audible.com/pd/The-Probability-of-Everything-Audiobook/B0BJ148HJN?qid=1686102329" target="_blank"> <button className="b_links">Audible</button></Link>

                            <Link to="https://bookshop.org/p/books/the-probability-of-everything/18908540" target="_blank"> <button className="b_links">Indiebound</button></Link>

                            <Link to="https://www.amazon.com/Probability-Everything-Sarah-Everett/dp/006325655X" target="_blank" ><button className="b_links">Amazon</button></Link>

                            <Link to="https://www.chapters.indigo.ca/en-ca/books/the-probability-of-everything/9780063256552-item.html" target="_blank" ><button className="b_links">Indigo</button></Link>

                            <Link to="https://www.goodreads.com/book/show/62870380-the-probability-of-everything" target="_blank"> <button className="b_links">Goodreads</button></Link>
                        </div>
                    </motion.div >
                    <div id="b_rev">
                        <div className="_bx">
                            <div className="_b">An instant classic.”<br /><br />
                                <i>— Christine Day, award winning author of I Can Make This Promise</i>
                            </div>
                        </div>
                        <div className="_bx">
                            <div className="_b">"...heartrending, hopeful, and palpably felt."<br /><br />
                                <i>— Publishers Weekly (starred review)</i></div></div>
                        <div className="_bx">
                            <div className="_b">"...gut-wrenching novel...  <br /><br />
                                <i>— Kirkus Reviews (starred review)</i></div></div>
                        <div className="_bx">
                            <div className="_b">"Thought-provoking and utterly unique..."<br /><br />
                                <i>— School Library Journal (starred review)</i></div> </div>

                    </div>
                    <h2>The Probability of Everything</h2>
                    <div className="bk_inner">
                        <p>
                            Eleven-year-old Kemi Carter loves scientific facts, specifically probability. It’s how she understands the world and her place in it. Kemi knows her odds of being born were 1 in 5 .5 trillion, and that the odds of her having the best family ever were even lower. Yet somehow, Kemi lucked out.
                        </p>
                        <p>
                            But everything Kemi thought she knew changes when she sees an asteroid hover in the sky, casting a purple haze over her world. Amplus-68 has an 84. 7% chance of colliding with earth in four days, and with that collision, Kemi’s life as she knows it will end.
                            But over the course of the four days, even facts don’t feel true to Kemi anymore. The new town she moved to that was supposed to be “better for her family” isn’t very welcoming. And Amplus-68 is taking over her life, but others are still going to school and eating at their favorite diner like nothing has changed. Is Kemi the only one who feels like the world is ending?
                        </p>
                        <p>
                            With the days numbered, Kemi decides to put together a time capsule that will capture her family’s truth: how creative her mother is, how inquisitive her little sister can be, and how much Kemi’s whole world revolves around her father. But no time capsule can change the truth behind all of it, that Kemi must face the most inevitable and hardest part of life: saying goodbye.
                        </p>

                    </div>
                </motion.div >
            </div >
        </AnimatePresence >
    );
}
export default TPOE;