import React, { useState } from "react";
import { NavLink as Link } from "react-router-dom";
import "../assets/App.css";
import im from "../assets/_img_md_1.svg";
import im_s from "../assets/_img_md 1_s.jpg";
import TPGuide from "../kit/tpoe-guide.pdf";
import { motion, AnimatePresence } from "framer-motion";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';



const variants = {
    hidden_up: {
        opacity: 0,
        zIndex: 0,
        y: 50
    },
    hidden_down: {
        opacity: 0,
        zIndex: 0,
        y: -50
    },
    center: {
        y: 0,
        zIndex: 1,
        opacity: 1
    }
}


const Media = () => {
    const [showSubA, setShowSubA] = useState(false);

    function handleClickSub(e) {

        if (e.target.className === "_a") {
            setShowSubA(!showSubA);
        }
        else {
            setShowSubA(showSubA);
        }
    }

    return (
        <AnimatePresence initial="false">

            <motion.div className="content_main">

                <motion.div
                    initial="hidden_down"
                    animate="center"
                    exit="hidden_up"
                    variants={variants}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    <LazyLoadImage className="inner_img"
                        placeholderSrc={im_s}
                        style={{
                            maxWidth: "85%",
                            userSelect: "none",
                            WebkitUserSelect: "none",
                            MozUserSelect: "none",
                            pointerEvents: "none"
                        }}
                        src={im}
                        alt="background line drawing"
                        effect="opacity" />
                </motion.div>
                <motion.div className="inner"
                    id="media"
                    initial="hidden_up"
                    animate="center"
                    exit="hidden_down"
                    variants={variants}
                    transition={{ duration: 1, delay: 0.5 }}
                >

                    <div className="media_fill">

                        <h1>Media</h1>
                        <nav className="media_nav">
                            <div className="_a" onClick={handleClickSub}>The Probability of Everything</div>
                            {showSubA ? (
                                <>
                                    <Link to={TPGuide} target="_blank">Discussion Guide</Link>
                                </>
                            ) : null}
                        </nav>
                    </div>
                </motion.div>
            </motion.div>
        </AnimatePresence >
    );
}

export default Media;