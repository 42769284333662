import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="ft">
            Copyright © 2024 Sarah Everett. Design by <Link to="http://cornersky.com" target="_blank"> CS </Link>
        </div>
    );
}
export default Footer;