import React from "react";
import bk from "../assets/b2.png";
import bk_s from "../assets/b2-min.png";
import "../assets/App.css";
import { motion, AnimatePresence } from "framer-motion";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { NavLink as Link } from "react-router-dom";

const variants = {
    hidden_up: {
        opacity: 0,
        zIndex: 0,
        y: 50
    },
    hidden_down: {
        opacity: 0,
        zIndex: 0,
        y: -50
    },
    center: {
        y: 0,
        zIndex: 1,
        opacity: 1
    }
}

const HTLWY = () => {
    return (
        <AnimatePresence initial="false">
            <div className="content_main">

                <motion.div className={"inner_cover"}
                    initial="hidden_down"
                    animate="center"
                    exit="hidden_up"
                    variants={variants}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    <LazyLoadImage className="inner_img"
                        placeholderSrc={bk_s}
                        style={{
                            maxWidth: "75%",
                            userSelect: "none",
                            WebkitUserSelect: "none",
                            MozUserSelect: "none",
                            pointerEvents: "none"
                        }}
                        src={bk}
                        alt="How to Live Without You cover"
                        effect="opacity" />
                </motion.div>


                <motion.div className="inner_bks"
                    initial="hidden_up"
                    animate="center"
                    exit="hidden_down"
                    variants={variants}
                    transition={{ duration: 1, delay: 0.5 }}
                >

                    <motion.div className="b_box"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.5, duration: 2 }}
                        exit="exit">
                        <div className="b_info">
                            <Link to="https://www.harpercollins.com/products/how-to-live-without-you-sarah-everett?variant=39935476727842" target="_blank"> <button className="b_links">HarperCollins Books</button></Link>

                            <Link to="https://www.audible.com/pd/How-to-Live-Without-You-Audiobook/B09R63K27R?qid=1686102329" target="_blank"> <button className="b_links">Audible</button></Link>

                            <Link to="https://www.indiebound.org/book/9780358256229" target="_blank"> <button className="b_links">Indiebound</button></Link>

                            <Link to="https://www.amazon.com/How-Live-Without-Sarah-Everett/dp/0358256224" target="_blank" ><button className="b_links">Amazon</button></Link>

                            <Link to="https://www.chapters.indigo.ca/en-ca/books/how-to-live-without-you/9780358256229-item.html" target="_blank" ><button className="b_links">Indigo</button></Link>

                            <Link to="https://www.goodreads.com/book/show/58311929-how-to-live-without-you" target="_blank"> <button className="b_links">Goodreads</button></Link>
                        </div>
                    </motion.div >
                    <h2>How to Live Without You</h2>
                    <div className="bk_inner">
                        <p >
                            When her sister Rose disappeared, seventeen-year-old Emmy lost a part of herself. Everyone else seems convinced she ran away and will reappear when she’s ready, but Emmy isn’t so sure. That doesn’t make sense for the Rose she knew: effervescent, caring, and strong-willed. So Emmy returns to their Ohio hometown for a summer, determined to uncover clues that can lead her back to Rose once and for all.
                        </p>
                        <p>
                            But what Emmy finds is a string of secrets and lies that she never thought possible, casting the person she thought she knew best in a whole new light.Reeling with confusion, Emmy decides to step into Rose’s life.She reconnects with their childhood best friend and follows in Rose’s last known footsteps with heart - wrenching consequences.
                        </p>
                        <p>
                            An honest and intimate look at sisterhood and the dark side of growing up, Sarah Everett’s latest novel is a stunning portrayal of how you can never truly know the ones you love.
                        </p>

                    </div>
                </motion.div >
            </div>
        </AnimatePresence>
    );
}
export default HTLWY;