import React from "react";
import bk from "../assets/b4.png";
import bk_s from "../assets/b4-min.png";
import "../assets/App.css";
import { motion, AnimatePresence } from "framer-motion";
import { NavLink as Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';


const variants = {
    hidden_up: {
        opacity: 0,
        zIndex: 0,
        y: 50
    },
    hidden_down: {
        opacity: 0,
        zIndex: 0,
        y: -50
    },
    center: {
        y: 0,
        zIndex: 1,
        opacity: 1
    }
}

const NOHIL = () => {
    return (
        <AnimatePresence initial="false">
            <div className="content_main">
                <motion.div className={"inner_cover"}
                    initial="hidden_down"
                    animate="center"
                    exit="hidden_up"
                    variants={variants}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    <LazyLoadImage className="inner_img"
                        placeholderSrc={bk_s}
                        style={{
                            maxWidth: "75%",
                            userSelect: "none",
                            WebkitUserSelect: "none",
                            MozUserSelect: "none",
                            pointerEvents: "none"
                        }}
                        src={bk}
                        alt="No One Here is Lonely cover"
                        effect="opacity" />
                </motion.div>
                <motion.div className="inner_bks"
                    initial="hidden_up"
                    animate="center"
                    exit="hidden_down"
                    variants={variants}
                    transition={{ duration: 1, delay: 0.5 }}
                >

                    <motion.div className="b_box"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.5, duration: 2 }}
                        exit={{ opacity: 0 }}>

                        <div className="b_info">
                            <Link to="https://www.penguinrandomhouse.com/books/252818/no-one-here-is-lonely-by-sarah-everett/" target="_blank"> <button className="b_links">Penguin Random House</button></Link>

                            <Link to="https://www.audible.com/pd/No-One-Here-Is-Lonely-Audiobook/B07L3C3N4W?qid=1686102329" target="_blank"> <button className="b_links">Audible</button></Link>

                            <Link to="https://bookshop.org/p/books/no-one-here-is-lonely-sarah-everett/11457019?ean=9780553538717" target="_blank"> <button className="b_links">Bookshop</button></Link>

                            <Link to="https://www.amazon.com/One-Here-Lonely-Sarah-Everett/dp/0553538683" target="_blank" ><button className="b_links">Amazon</button></Link>

                            <Link to="https://www.chapters.indigo.ca/en-ca/books/no-one-here-is-lonely/9780553538687-item.html" target="_blank" ><button className="b_links">Indigo</button></Link>

                            <Link to="https://www.goodreads.com/book/show/39872929-no-one-here-is-lonely" target="_blank"> <button className="b_links">Goodreads</button></Link>
                        </div>
                    </motion.div >

                    <h2>No One Here is Lonely</h2>
                    <div className="bk_inner">
                        <p >
                            Eden has always had two loves: her best friend, Lacey, and her crush, Will. And then, almost simultaneously, she loses them both. Will to a car accident and Lacey to the inevitable growing up and growing apart.
                        </p>
                        <p>
                            Devastated by the holes they have left in her life, Eden finds solace in an unlikely place. Before he died, Will set up an account with In Good Company, a service that uploads voices and emails and creates a digital companion that can be called anytime, day or night. It couldn’t come at a better time because, after losing Lacey–the hardest thing Eden has had to deal with–who else can she confide all her secrets to? Who is Eden without Lacey?
                        </p>
                        <p>
                            As Eden falls deeper into her relationship with “Will,” she hardly notices as her real life blooms around her. There is a new job, new friends. Then there is Oliver. He’s Lacey’s twin, so has always been off-limits to her, until now. He may be real, but to have him, will Eden be able to say goodbye to Will?
                        </p>

                    </div>
                </motion.div >
            </div >
        </AnimatePresence >
    );
}
export default NOHIL;